import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Register extends Component {
  constructor(props) {
    super(props);

    this.onValueChange = this.onValueChange.bind(this);
    this.canContinue = this.canContinue.bind(this);
    this.nextStep = this.nextStep.bind(this);
  }

  static propTypes = {
    clientid: PropTypes.string,
    config: PropTypes.object,
    cycle: PropTypes.object,
    setRegValue: PropTypes.func,
    addInput: PropTypes.func,
    removeInput: PropTypes.func,
    saveStep: PropTypes.func
  };

  onValueChange(...keys) {
    return (e) => {
      const target = e.target;
      const value = target.value;
      const isNumber = target.getAttribute('data-value-type') === 'number'; ///^\d+$/.test(value);
      const finalValue = isNumber ? Number(value) : value;
      this.props.setRegValue({
        keys: keys,
        value: finalValue
      });
    };
  }

  canContinue() {
    const { config, cycle } = this.props;
    const { input, material, consumption } = cycle;

    if (input.some((item) => item.weight === '' || item.weight === 0)) {
      console.log('missing input');
      return false;
    }
    if (material === '' || material === undefined) {
      console.log('missing material');
      return false;
    }
    if (config.salt && consumption.salt === '') {
      console.log('missing salt');
      return false;
    }

    return true;
  }

  nextStep() {
    if (!this.canContinue()) {
      return;
    }

    this.props.saveStep();
  }

  render() {
    const { config, cycle, addInput, removeInput } = this.props;
    const { input, material, salt } = cycle;

    return (
      <section className="register">
        <div className="register-row">
          <div className="flex-360">
            <div className="form-group">
              <label className="form-control form-numbers">
                <span>Lot number</span>
                <div disabled className="input">
                  &nbsp;
                </div>
                <div disabled className="input">
                  &nbsp;
                </div>
                <div disabled className="input">
                  &nbsp;
                </div>
              </label>
            </div>
          </div>
          <div className="flex-360"></div>
          <div className="flex-360 text-right">
            <button
              disabled={!this.canContinue()}
              className="btn btn-primary btn-continue"
              type="button"
              onClick={this.nextStep}
            >
              Continue cycle
            </button>
          </div>
        </div>

        <div className="register-row">
          <div className="flex-360">
            {input.map((inputItem, index) => {
              return (
                <div className="box form-box dross-box" key={index}>
                  {index > 0 && (
                    <button onClick={removeInput} className="btn btn-square btn-dross-remove">
                      -
                    </button>
                  )}
                  {config.containers && (
                    <div className="form-group">
                      <label className="form-control input-group">
                        <span>Container</span>
                        <input
                          className="input"
                          type="text"
                          value={inputItem.container}
                          onChange={this.onValueChange('input', index, 'container')}
                        />
                      </label>
                    </div>
                  )}
                  <div className="form-group">
                    <label className="form-control input-group">
                      <span>Weight</span>
                      <input
                        className="input"
                        type="text"
                        pattern="[0-9]*"
                        data-value-type="number"
                        value={inputItem.weight}
                        onChange={this.onValueChange('input', index, 'weight')}
                      />
                      <span className="input-type">kg</span>
                    </label>
                  </div>
                </div>
              );
            })}
            {config.allowcontainercombine && input.length === 1 && (
              <div className="box" style={{ textAlign: 'right', padding: '8px' }}>
                <button onClick={addInput} className="btn btn-square">
                  +
                </button>
              </div>
            )}
          </div>
          <div className="flex-360">
            <div className="form-group">
              <label className="form-control input-group">
                <span>Material</span>
                <select className="input" onChange={this.onValueChange('material')}>
                  <option value="">Select one</option>
                  {config?.materials?.map((opt) => (
                    <option key={opt.id} value={opt.id}>
                      {opt.name}
                    </option>
                  ))}
                </select>
              </label>
            </div>

            {config.salt && (
              <div className="form-group">
                <label className="form-control input-group">
                  <span>Salt</span>
                  <input
                    className="input"
                    type="text"
                    pattern="[0-9]*"
                    data-value-type="number"
                    value={salt}
                    onChange={this.onValueChange('consumption', 'salt')}
                  />
                </label>
              </div>
            )}
          </div>
          <div className="flex-360"></div>
        </div>
      </section>
    );
  }
}

export default Register;
