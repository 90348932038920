import React, { useState } from 'react';
import { baseApi } from '../common/config.js';
import { post } from '../common/utils/http.js';
import { Logo } from '../components/icons.js';

export default function LoginPage({ setAuthToken }) {
  const [message, setMessage] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  function onChangeName(e) {
    setUsername(e.target.value);
  }
  function onChangePassword(e) {
    setPassword(e.target.value);
  }

  function onSubmit() {
    setMessage('');
    if (username !== '' && password !== '') {
      post(`${baseApi}/auth/login`, { username, password })
        .then((data) => {
          setAuthToken(data.token);
        })
        .catch((ex) => {
          console.log(ex);
          setMessage('Incorrect username or password.');
        });
    }
  }
  
  function onKeyUp(e) {
    if (e.keyCode === 13) {
      onSubmit();
    }
  }

  return (
    <div className="login-wrapper">
      <div className="login-logo-wrapper">
        <Logo className="login-logo" />
      </div>
      <div className="form-group">
        <label className="form-control">
          <span className="sr-only">Username</span>
          <input
            className="input input-rounded"
            type="text"
            placeholder="Username"
            value={username}
            onKeyUp={onKeyUp}
            onChange={onChangeName}
          />
        </label>
        <label className="form-control">
          <span className="sr-only">Password</span>
          <input
            className="input input-rounded"
            type="password"
            placeholder="Password"
            value={password}
            onKeyUp={onKeyUp}
            onChange={onChangePassword}
          />
        </label>
        <div className="login-button">
          <button
            disabled={username.length === 0 || password.length === 0}
            className="btn btn-primary btn-continue"
            onClick={onSubmit}
          >
            Login
          </button>
        </div>
        {message !== '' && <div className="text-center">{message}</div>}
      </div>
    </div>
  );
}
