import { useEffect, useState } from 'react';
import { baseApi } from '../common/config';
import { get, post } from '../common/utils/http';

function useReceived() {
  const [client, selectClient] = useState('');
  const [weight, setWeight] = useState(0);
  const [material, setMaterial] = useState('');
  const [container, setContainer] = useState('');

  function saveReceived() {
    return post(`${baseApi}/receive`, {
      clientid: client,
      weight,
      material,
      container
    });
  }

  return {
    client,
    selectClient,
    weight,
    setWeight,
    material,
    setMaterial,
    container,
    setContainer,
    saveReceived
  };
}

export default useReceived;
