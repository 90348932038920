import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import Auth from '../common/auth';
import { userState } from '../state/user.js';
import { clientsState } from '../state/clients.js';


import Dashboard from './dashboard.js';
import CycleList from './sections/cycles.js';
import DeliveryPage from '../delivery/delivery.js';
import ReceivePage from '../receive/receive.js';

function HomePage() {
  const [user, setUser] = useRecoilState(userState)
  const [clients, setClients] = useRecoilState(clientsState);

  function onLogout() {
    Auth.logout();
    setUser(null);
    setClients(null);
  }

  return (
    <div className="home-wrapper split-view">
      <Routes>
        <Route path="/" element={<Dashboard user={user} clients={clients} onLogout={onLogout} />} />
        <Route path="delivery/in" element={<ReceivePage clients={clients} />} />
        <Route path="delivery/out" element={<DeliveryPage clients={clients} />} />
      </Routes>
      <div className="split-part split-504 flex-col">
        <CycleList clients={clients} />
      </div>
    </div>
  );
}

export default HomePage;