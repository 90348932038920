import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// TODO: Use shared
export const CYCLE_STAGES = {
  STAGE_1: 'STAGE_1',
  STAGE_2: 'STAGE_2',
  STAGE_3: 'STAGE_3',
  FINISHED: 'FINISHED'
};

import continueIcon from '../../assets/continue.svg';
import { getYield } from '../../common/cycles';

const renderDate = function (date) {
  console.log(date);
  const minutes = date.getMinutes();

  return `${date.getDate()}/${date.getMonth() + 1} ${date.getHours()}:${minutes < 10 ? '0' + minutes : minutes}`;
};

function CycleList(props) {
  const { clients, cycles, onCycleSelect } = props;

  return (
    <section className="cycle-banner">
      <div className="list-table cycle-items">
        <div className="table-head">
          <div>Date</div>
          <div>Lot</div>
          <div>Client</div>
          <div>Yield</div>
        </div>
        {cycles.map((item) => {
          const client = clients.find((cl) => cl.id === item.clientid);
          const clientName = client ? client.name : 'Unknown';
          const yeildGoal = client.config.yieldgoal || 100;
          const cycleYield = getYield(item);
          const isFinished = item.state === CYCLE_STAGES.FINISHED;
          const date = new Date(item.createdat);
          const yieldClass = cycleYield >= yeildGoal ? 'yield-goal' : '';

          return (
            <div
              key={item.id}
              onClick={() => {
                onCycleSelect(item, client);
              }}
              className="table-bar table-no-bar"
            >
              <div>{renderDate(date)}</div>
              <div className="table-row-15">{item.lotnumber}</div>
              <div className="table-row-35">{clientName}</div>
              <div className={`table-bar-right ${yieldClass}`}>
                {isFinished ? (
                  `${cycleYield.toFixed(1)}%`
                ) : (
                  <Link to={`/register/${item.clientid}/${item.id}`}>
                    <img height="16" src={continueIcon} />
                  </Link>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}

CycleList.propTypes = {
  clients: PropTypes.array,
  cycles: PropTypes.array,
  onCycleSelect: PropTypes.func
};

export default CycleList;
