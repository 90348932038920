import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toFixedLength } from '../../common/utils/numbers.js';
import { inputUnits } from '../../common/cycles.js';

class RegisterStage2 extends Component {
  constructor(props) {
    super(props);

    this.onValueChange = this.onValueChange.bind(this);
    this.canContinue = this.canContinue.bind(this);
    this.nextStep = this.nextStep.bind(this);
  }

  static propTypes = {
    clientid: PropTypes.string,
    config: PropTypes.object,
    cycle: PropTypes.object,
    setRegValue: PropTypes.func
  };

  componentDidMount() {}

  componentWillUnmount() {}

  onValueChange(...keys) {
    return (e) => {
      const target = e.target;
      const value = target.value;
      const isNumber = target.getAttribute('data-value-type') === 'number'; ///^\d+$/.test(value);
      const finalValue = isNumber ? Number(value) : value;

      this.props.setRegValue({
        keys: keys,
        value: finalValue
      });
    };
  }

  canContinue() {
    const { cycle, config } = this.props;
    const { cycletime } = cycle.consumption;

    if (cycletime === '' || cycletime === 0) {
      return false;
    }
    if (
      config.consumes.some((inpType) => {
        return cycle.consumption[inpType] === '' || cycle.consumption[inpType] === 0;
      })
    ) {
      return false;
    }

    return true;
  }
  nextStep() {
    if (!this.canContinue()) {
      return;
    }
    this.props.saveStep();
  }

  render() {
    const { cycle, config } = this.props;
    const { consumption, lotnumber } = cycle;
    const { oxygen, oil, cycletime } = consumption;

    const lotnum = toFixedLength(lotnumber, 3).split('');

    return (
      <section className="register register-2">
        <div className="register-row">
          <div className="flex-360">
            <div className="form-group">
              <label className="form-control form-numbers">
                <span>Lot number</span>
                <div className="input">{lotnum[0]}</div>
                <div className="input">{lotnum[1]}</div>
                <div className="input">{lotnum[2]}</div>
              </label>
            </div>
          </div>
          <div className="flex-360"></div>
          <div className="flex-360 text-right">
            <button
              disabled={!this.canContinue()}
              className="btn btn-primary btn-continue"
              type="button"
              onClick={this.nextStep}
            >
              Continue cycle
            </button>
          </div>
        </div>

        <div className="register-row">
          <div className="box form-box flex-360">
            {config.consumes.map((inpType) => (
              <label className="form-control input-group">
                <span className="capitalize">{inpType.name}</span>
                <input
                  className="input"
                  type="text"
                  pattern="[0-9]*"
                  data-value-type="number"
                  value={consumption[inpType.id]}
                  onChange={this.onValueChange('consumption', inpType.id)}
                />
                <span className="input-type">{inpType.unit}</span>
              </label>
            ))}
          </div>
          <div className="flex-360">
            <div className="form-group">
              <label className="form-control input-group">
                <span>Cycletime</span>
                <input
                  className="input"
                  type="text"
                  pattern="[0-9]*"
                  data-value-type="number"
                  value={cycletime}
                  onChange={this.onValueChange('consumption', 'cycletime')}
                />
                <span className="input-type">min</span>
              </label>
            </div>
          </div>
          <div className="flex-360"></div>
        </div>
      </section>
    );
  }
}

export default RegisterStage2;
