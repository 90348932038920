import React from 'react';
import useAuth from './hooks/useAuth';

import LoginPage from './login/login.js';
import AppRouter from './routes.js';

export default function App() {
  const { isReady, isLoggedin, setToken, logOut } = useAuth();
  console.log('isLoggedin', isLoggedin);
  if (!isReady) {
    return <div>Checking login..</div>;
  }
  if (!isLoggedin) {
    return <LoginPage setAuthToken={setToken} />;
  }
  return <AppRouter />;
}
