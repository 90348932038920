import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Home from './home/home.js';
import NotFound from './notfound/notfound.js';
import Register from './register/register.js';

export default function AppRouter() {
  return (
    <div className="main-content">
      <Routes>
        <Route path="/home/*" element={<Home />} />
        <Route path="/register/:clientid" element={<Register />} />
        <Route path="/register/:clientid/:cycleid" element={<Register />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="/" element={<Navigate replace to="/home" />} />
      </Routes>
    </div>
  );
}
