import getStorage from './utils/storage.js';

const STORAGE_NAME = 'authorizationData';
const storage = getStorage();

const user = {
  isAuth: !!storage.getItem(STORAGE_NAME)
};

const decodeToken = (token) => {
  let header;
  let claim;
  let signature;

  token = token || '';
  const parts = token.split('.');

  if (parts.length === 3) {
    [header, claim, signature] = parts;
    header = JSON.parse(decodeURIComponent(escape(atob(header))));
    claim = JSON.parse(decodeURIComponent(escape(atob(claim))));
  }

  return {
    header,
    claim,
    signature
  };
};

const setToken = (token) => {
  localStorage.setItem(STORAGE_NAME, token);
  user.isAuth = true;
};

const logout = () => {
  storage.removeItem(STORAGE_NAME);
  user.isAuth = false;
};

const getAuthData = () => {
  const authData = storage.getItem(STORAGE_NAME);

  let userData = {};

  if (authData) {
    const decodedTokenJson = decodeToken(authData);

    user.isAuth = true;

    userData = {
      ...decodedTokenJson.claim
    };
  }

  // Expired token
  if (user.isAuth && userData.expires * 1000 < Date.now()) {
    logOut();
    return {
      exp: true
    };
  }

  return {
    exp: false,
    isAuth: user.isAuth,
    ...userData
  };
};

const getToken = () => {
  const authData = storage.getItem(STORAGE_NAME);

  if (authData) {
    return authData;
  }

  return null;
};

export default {
  setToken,
  logout,
  user,
  getToken
}