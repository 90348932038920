import React from 'react';
import PropTypes from 'prop-types';
import { filledPot } from './pot2.js';
import { getYield, inputUnits } from '../common/cycles.js';

const renderDate = function (date) {
  const minutes = date.getMinutes();
  return `${date.getDate()}/${date.getMonth() + 1} ${date.getHours()}:${minutes < 10 ? '0' + minutes : minutes}`;
};

function Receipt(props) {
  const { cycle, client, onBackClick } = props;

  const totalAluminium = cycle?.output?.reduce((sum, al) => sum + al, 0) ?? 0;
  const totalDross = cycle?.input?.reduce((sum, dross) => sum + dross.weight, 0) ?? 0;
  const yieldPercent = getYield(cycle);
  const yields = isNaN(yieldPercent) ? 0 : Number(yieldPercent.toFixed(1));
  const yieldClass = yields >= (client.config.yieldgoal || 100) ? 'yield-goal' : '';
  const residue = totalDross - totalAluminium;
  const date = cycle.createdat ? new Date(cycle.createdat) : new Date();

  return (
    <section className="register-receipt">
      <div className="list-table">
        <div className="table-head">
          <div>Date</div>
          <div>Lot</div>
          <div>Client</div>
          <div>Yield</div>
        </div>
        <div className="table-bar">
          <div>{renderDate(date)}</div>
          <div className="table-row-15">{cycle.lotnumber}</div>
          <div className="table-row-35">{client.name}</div>
          <div className={`table-bar-right ${yieldClass}`}>{yields}%</div>
        </div>
        <div className="table-body">
          <div className="table-row">
            <div className="table-row-half table-row-head">Cycletime</div>
            <div className="table-row-half table-row-content">{cycle.consumption.cycletime}</div>
          </div>
          <div className="table-row">
            <div className="table-row-half table-row-head">Material</div>
            <div className="table-row-half table-row-content">{cycle.material}</div>
          </div>
          {cycle.input?.map((input, i) => (
            <div className="table-row" key={i}>
              <div className="table-row-half table-row-head">{input.container ? input.container : 'Weight'} (kg)</div>
              <div className="table-row-half table-row-content">{input.weight}</div>
            </div>
          ))}
          {client.config?.consumes?.map((inpType) => {
            return (
              <div className="table-row" key={inpType.id}>
                <div className="table-row-half table-row-head">
                  <span className="capitalize">{inpType.name}</span> ({inpType.unit})
                </div>
                <div className="table-row-half table-row-content">{cycle.consumption[inpType.id] ?? ''}</div>
              </div>
            );
          })}
          {client.config.salt && (
            <div className="table-row">
              <div className="table-row-half table-row-head">Salt (kg)</div>
              <div className="table-row-half table-row-content">{cycle.consumption.salt || ''}</div>
            </div>
          )}
          <div className="table-row">
            <div className="table-row-half table-row-head">Residue (kg)</div>
            <div className="table-row-half table-row-content">{residue || ''}</div>
          </div>
          <div className="table-row" style={{ borderBottom: 'none' }}>
            <div className="table-row-full table-row-head">Aluminium (kg)</div>
          </div>
          <div className="table-row">
            <div className="table-row-full receipt-aluminium-list">
              {!cycle.output || cycle.output.length === 0 ? (
                <span className="receipt-aluminium"></span>
              ) : (
                cycle.output
                  .filter((al) => al > 0)
                  .map((al, i) => {
                    const percent = al / Math.max(...cycle.output);
                    return (
                      <div className="receipt-aluminium" key={i}>
                        <span className="receipt-aluminium-number">{al}</span>
                        <div
                          className="receipt-aluminium-icon"
                          dangerouslySetInnerHTML={{
                            __html: filledPot(`pot-${i}`, percent)
                          }}
                        ></div>
                      </div>
                    );
                  })
              )}
            </div>
          </div>
          <div className="table-row">
            <div className="table-row-half table-row-head">Total</div>
            <div className="table-row-half table-row-content">{totalAluminium}</div>
          </div>
          <div className="table-row">
            <div className="table-row-half table-row-head">Yield</div>
            <div className={`table-row-half table-row-content ${yieldClass}`}>{yields}%</div>
          </div>
          {onBackClick && (
            <div className="table-row-button">
              <button className="btn" onClick={onBackClick}>
                Back
              </button>
            </div>
          )}
          <div className="table-row table-row-pusher"></div>
        </div>
      </div>
    </section>
  );
}

Receipt.propTypes = {
  client: PropTypes.object,
  cycle: PropTypes.object,
  onBackClick: PropTypes.func
};

export default Receipt;
