import { useEffect, useState } from 'react';
import { baseApi } from '../common/config';
import { get, post } from '../common/utils/http';

export default function useDelivery() {
  const [cycleOptions, setCycleOptions] = useState([]);
  const [cycles, setCycles] = useState([]);
  const [client, setClient] = useState();

  useEffect(() => {
    if (!client) {
      setCycles([]);
      setCycleOptions([]);
      return;
    }

    get(`${baseApi}/delivery/options?client=${client}`).then((data) => {
      setCycleOptions(data);
      setCycles([]);
    });
  }, [client]);

  function saveDelivery() {
    return post(`${baseApi}/delivery`, {
      clientid: client,
      cycles: cycles
    }).then((data) => {
      return data;
    });
  }

  function selectClient(clientid) {
    setClient(clientid);
    setCycles([]);
  }

  function addCycle(cycleid) {
    setCycles([...cycles, cycleid]);
  }

  function removeCycle(cycleid) {
    setCycles([...cycles.filter((cycle) => cycle !== cycleid)]);
  }

  function getWeight() {
    return cycleOptions
      .filter((cycle) => cycles.includes(cycle.id))
      .reduce((sum, cycle) => {
        return sum + cycle.data.aluminium.reduce((tot, al) => tot + al, 0);
      }, 0);
  }

  return {
    cycleOptions,
    cycles,
    client,
    selectClient,
    addCycle,
    removeCycle,
    getWeight,
    saveDelivery
  };
}
