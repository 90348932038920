import { useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';
import { baseApi } from '../common/config.js';
import { get } from '../common/utils/http.js';
import getStorage from '../common/utils/storage.js';
import { userState } from '../state/user.js';
import { clientsState } from '../state/clients.js';
import Auth from '../common/auth.js';

const STORAGE_NAME = 'authorizationData';
const storage = getStorage();

export default function useAuth() {
  const [userToken, setUserToken] = useState(Auth.getToken());
  const [user, setUser] = useRecoilState(userState);
  const [clients, setClients] = useRecoilState(clientsState);
  const [isLoggedin, setIsLoggedin] = useState(false);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (userToken && !user) {
      get(`${baseApi}/auth/self`)
        .then((data) => {
          setUser(data.user);

          return get(`${baseApi}/clients`).then((data) => {
            setClients(data);
          });
        })
        .then(() => {
          console.log('isReady...');
          setIsReady(true);
        })
        .catch((ex) => {
          console.log(ex);
          logOut();
        });
    } else {
      setIsReady(true);
    }
  }, [userToken]);

  useEffect(() => {
    console.log('user', user);
    console.log('clients', clients);
    if (user && clients) {
      setIsLoggedin(true);
    }
  }, [user, clients]);

  function logOut() {
    //storage.removeItem(STORAGE_NAME);
    setUser(null);
    setClients([]);
    //setAuthToken(null);
    setIsReady(true);
    setIsLoggedin(false);
  }

  function setToken(token) {
    Auth.setToken(token);
    setUserToken(token);
  }

  function getToken() {
    return Auth.getToken();
  }

  return {
    isLoggedin,
    isReady,
    user,
    clients,
    setToken,
    getToken,
    logOut
  };
}
