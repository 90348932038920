import React from 'react';
import { useParams } from 'react-router-dom';
import useCycle from './use-cycle.js';

import Register from './stages/register.js';
import Register2 from './stages/register2.js';
import Register3 from './stages/register3.js';
import RegisterFinished from './stages/finished.js';
import Receipt from '../receipt/receipt.js';
import RegisterHeader from './register-header.js';

function RegisterContainer() {
  const { clientid, cycleid } = useParams();

  const {
    CYCLE_STAGES,
    isReady,
    config,
    client,
    cycle,
    saveStep,
    setRegValue,
    addInput,
    removeInput,
    addAluminium,
    rmAluminium
  } = useCycle(clientid, cycleid);

  if (!isReady) {
    return <p>Loading...</p>;
  }

  function renderCurrentStep() {
    switch (cycle.state) {
      case CYCLE_STAGES.STAGE_1:
        return (
          <Register
            clientid={clientid}
            config={config}
            cycle={cycle}
            setRegValue={setRegValue}
            addInput={addInput}
            removeInput={removeInput}
            saveStep={saveStep}
          ></Register>
        );
      case CYCLE_STAGES.STAGE_2:
        return (
          <Register2
            clientid={clientid}
            config={config}
            cycle={cycle}
            setRegValue={setRegValue}
            saveStep={saveStep}
          ></Register2>
        );
      case CYCLE_STAGES.STAGE_3:
        return (
          <Register3
            clientid={clientid}
            config={config}
            cycle={cycle}
            setRegValue={setRegValue}
            saveStep={saveStep}
            addAluminium={addAluminium}
            rmAluminium={rmAluminium}
          ></Register3>
        );
      case CYCLE_STAGES.FINISHED:
        return <RegisterFinished />;
      default:
        return <h2>Unknown state: {cycle.state}</h2>;
    }
  }

  if (cycle.state === 'NONE') {
    return (
      <div className="split-view">
        <div className="split-part split-left">
          <p>melting data...</p>
        </div>
        <div className="split-part split-right split-504"></div>
      </div>
    );
  }

  return (
    <div className="register-container">
      <RegisterHeader clientid={clientid} client={client} currentStep="" />
      <div className="split-view split-2">
        <div className="split-part split-left">{renderCurrentStep()}</div>
        <div className="split-part split-right split-504">
          <Receipt clientid={clientid} client={client} cycle={cycle} />
        </div>
      </div>
    </div>
  );
}

export default RegisterContainer;
