import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Graph from './graph.js';

import { get } from '../../common/utils/http.js';
import { baseApi } from '../../common/config.js';

import { formatWeight, formatPercent } from '../../common/utils/units.js';

// TODO: Do it for real..
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

class StatsBox extends Component {
  static propTypes = {
    clients: PropTypes.array
  };

  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      data: {},
      daysinmonth: 31,
      combined: {},
      hasError: false,
      clients: []
    };
  }

  componentDidMount() {
    const { clients } = this.props;

    // TODO: Move these things out of the component
    get(`${baseApi}/cycles/stats`).then((data) => {
      this.setState({
        ready: true,
        data: data.data,
        daysinmonth: data.daysinmonth
      });
    });
    /*
    get(`${baseApi}/cycles/report`).then((data) => {
      // Flatten data
      const items = Object.values(data).reduce((sum, item) => {
        return [...sum, ...item];
      }, []);

      const combined = clients.reduce((sum, client) => {
        const separated = client.config.materials.filter((mat) => mat.separate).map((mat) => mat.id.toLowerCase());

        const clData = [...separated, 'dross'].reduce((clSum, it) => {
          return {
            ...clSum,
            [it]: {
              al: 0,
              salt: 0,
              vol: 0,
              yield: 0
            }
          };
        }, {});

        return {
          ...sum,
          [client.name]: clData
        };
      }, {});

      items.forEach((item) => {
        const ref = combined[item.client][item.material] || combined[item.client].dross;
        ref.al += item.aluminium;
        ref.vol += item.volume;
        ref.salt += item.salt || 0;
        ref.yield = ref.al / ref.vol;
      });

      const clientsWithData = Object.keys(combined).filter((clientName) => {
        return Object.values(combined[clientName]).some((material) => material.al !== 0 || material.vol !== 0)
      });

      this.setState({
        combined: combined,
        clients: clientsWithData
      });
    });
*/
  }

  render() {
    const { clients } = this.props;
    const { data, daysinmonth, ready, hasError } = this.state;
    const date = new Date();

    if (hasError) {
      return (
        <section className="graph-box">
          <div className="box">Something went wrong..</div>
          <div className="box">Something went wrong..</div>
        </section>
      );
    }

    return (
      <section className="graph-box">
        {Object.entries(data).map(([clientid, clientData]) => {
          const client = clients.find((cl) => cl.id === clientid);
          const data = Object.values(clientData);
          const lastData = data[data.length - 1];
          return (
            <div className="box" key={clientid}>
              <div className="graph-top">
                <h2>{client.name}</h2>
                <div className="flex-row justify-around">
                  <div className="graph-comp-extra" key={client.id}>
                    <span>
                      {formatWeight(lastData.accvol)} ({formatPercent(lastData.accyield)})
                    </span>
                  </div>
                </div>
              </div>
              {ready && <Graph data={data} daysinmonth={daysinmonth} />}
            </div>
          );
        })}
      </section>
    );
  }
}

export default StatsBox;
