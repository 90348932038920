export function getYield(cycle) {
  // TODO: missing salt!!
  const input = cycle?.input?.reduce((sum, inp) => inp.weight + sum, 0) ?? 0;
  const salt = cycle?.consumption?.salt ?? 0;
  const output = cycle?.output?.reduce((sum, out) => out + sum, 0) ?? 0;

  return output / (input + salt) * 100;
}

export function inputUnits(inpType){
  switch(inpType) {
    case 'el':
      return 'kW';
    case 'oxygen':
      return 'Nm³'
    case 'oil':
      return 'l';
    default:
      return '';
  }
}