import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class ClientList extends Component {
  constructor(props) {
    super(props);

    this.onSelect = this.onSelect.bind(this);
    this.ignoreDisabled = this.ignoreDisabled.bind(this);

    this.state = {
      selected: '',
    };
  }

  static propTypes = {
    clients: PropTypes.array,
  };

  ignoreDisabled(e) {
    if (e.target.hasAttribute('disabled')) {
      e.preventDefault();
    }
  }

  onSelect(e) {
    this.setState({
      selected: e.target.value,
    });
  }

  render() {
    const { clients } = this.props;
    const { selected } = this.state;

    return (
      <section className="index-banner" style={{ height: 'calc(100% - 160px)' }}>
        <div className="flex-col" style={{ height: '100%', textAlign: 'center' }}>
          <div className="flex-row h-100">
            <div className="form-group flex-16">
              <label className="form-control input-group">
                <select className="input" onChange={this.onSelect} value={selected}>
                  <option value="">Select one</option>
                  {clients.map((client) => (
                    <option key={client.id} value={client.id}>
                      {client.name}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            <Link
              disabled={!selected}
              onClick={this.ignoreDisabled}
              className="btn btn-primary"
              to={`/register/${selected}`}
            >
              Start new cycle
            </Link>
          </div>
        </div>
      </section>
    );
  }
}

export default ClientList;
