import { useState, useEffect } from 'react';
import { post, get, put } from '../common/utils/http';
import { baseApi } from '../common/config';
import { useRecoilValue } from 'recoil';
import { clientsState } from '../state/clients';

export const CYCLE_STAGES = {
  STAGE_1: 'STAGE_1',
  STAGE_2: 'STAGE_2',
  STAGE_3: 'STAGE_3',
  FINISHED: 'FINISHED'
};

function initialState() {
  return {
    input: [
      {
        weight: '',
        container: ''
      }
    ],
    output: [],
    consumption: {},
    clientid: '',
    state: CYCLE_STAGES.STAGE_1
  };
}

const changeValue = function (data, keys, value) {
  if (keys.length === 0) {
    return value;
  }

  if (data instanceof Array) {
    return [...data.slice(0, keys[0]), changeValue(data[keys[0]], keys.slice(1), value), ...data.slice(keys[0] + 1)];
  }
  if (keys.length > 1) {
    return {
      ...data,
      [keys[0]]: changeValue(data[keys[0]], keys.slice(1), value)
    };
  }

  return {
    ...data,
    [keys[0]]: value
  };
};

export default function useCycle(clientid, cycleid) {
  const [isReady, setIsReady] = useState(false);
  const [client, setClient] = useState();
  const [cycle, setCycle] = useState(initialState());
  const [config, setConfig] = useState({});
  const clients = useRecoilValue(clientsState);

  useEffect(() => {
    if (clientid) {
      const client = clients.find((cl) => cl.id === clientid);
      setClient(client);
    }
  }, [clientid, clients]);

  useEffect(() => {
    if (!client) {
      return;
    }
    setConfig(client.config);
    if (cycleid) {
      get(`${baseApi}/cycles/${cycleid}`).then((data) => {
        setCycle(data);
        setIsReady(true);
      });
    } else {
      setCycle({
        ...cycle,
        clientid: client.id
      });
      setIsReady(true);
    }
  }, [cycleid, client]);

  function nextStep() {
    const stages = Object.values(CYCLE_STAGES);
    const currIndex = stages.indexOf(cycle.state);
    return stages[currIndex + 1];
  }

  function saveStep() {
    // If it already exists we do update
    if (cycle.id) {
      put(`${baseApi}/cycles/${cycle.id}`, {
        state: nextStep(),
        consumption: cycle.consumption,
        output: cycle.output.filter((al) => al > 0) // only include aluminium over 0
      }).then((data) => {
        setCycle(data);
      });
    } else {
      post(`${baseApi}/cycles`, {
        ...cycle,
        state: nextStep()
      }).then((data) => {
        setCycle(data);
      });
    }
  }

  function setRegValue({ keys, value }) {
    setCycle((cycle) => {
      const cycleData = changeValue(cycle, keys, value);
      console.log(keys, value);
      console.log(cycleData);
      return cycleData;
    });
  }

  const addInput = () => {
    setCycle({
      ...cycle,
      input: [...cycle.input, { weight: '', container: '' }]
    });
  };

  const removeInput = () => {
    setCycle({
      ...cycle,
      input: [cycle.input[0]]
    });
  };

  const addAluminium = () => {
    setCycle((cycle) => ({
      ...cycle,
      output: [...cycle.output, 0]
    }));
  };

  const rmAluminium = (index) => {
    setCycle((cycle) => ({
      ...cycle,
      output: cycle.data.aluminium.filter((al, i) => i !== index)
    }));
  };

  return {
    CYCLE_STAGES,
    isReady,
    config,
    client,
    cycle,
    saveStep,
    setRegValue,
    addInput,
    removeInput,
    addAluminium,
    rmAluminium
  };
}
